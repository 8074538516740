
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as healthJIizRD9wwmACHizTMp9bC30v0we91eJSSpVVFXeSJXQMeta } from "/codebuild/output/src3069017492/src/bitbucket.org/magusa/cavu-workspaces/apps/jetpark/pages/health.vue?macro=true";
import { default as _91_46_46_46slug_93UeeKva2ck_459ycsMuwxb5kv3mgg65KjTEdtkJQCj8XGkMeta } from "/codebuild/output/src3069017492/src/bitbucket.org/magusa/cavu-workspaces/apps/_base/pages/[...slug].vue?macro=true";
import { default as component_45stubG4t3jMowKfAnJz__I03dvvv6q_XmxcNr1rs8Xjj_67oMeta } from "/codebuild/output/src3069017492/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@8.57.1_ioredis@_c26b79983e398c03b663a917f4a5ee24/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubG4t3jMowKfAnJz__I03dvvv6q_XmxcNr1rs8Xjj_67o } from "/codebuild/output/src3069017492/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@8.57.1_ioredis@_c26b79983e398c03b663a917f4a5ee24/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "health___en",
    path: "/health",
    component: () => import("/codebuild/output/src3069017492/src/bitbucket.org/magusa/cavu-workspaces/apps/jetpark/pages/health.vue")
  },
  {
    name: "slug___en",
    path: "/:slug(.*)*",
    component: () => import("/codebuild/output/src3069017492/src/bitbucket.org/magusa/cavu-workspaces/apps/_base/pages/[...slug].vue")
  },
  {
    name: component_45stubG4t3jMowKfAnJz__I03dvvv6q_XmxcNr1rs8Xjj_67oMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubG4t3jMowKfAnJz__I03dvvv6q_XmxcNr1rs8Xjj_67o
  }
]