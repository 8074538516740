import { defineNuxtPlugin } from '#app/nuxt'
import { LazyBreadcrumbs, LazyButton, LazyCheckmarkList, LazyCoreAccordion, LazyCoreAccordionList, LazyCoreAdvancedTitle, LazyCoreBanner, LazyCoreCountdownSection, LazyCoreDynamicComponents, LazyCoreEventComponent, LazyCoreFeatureList, LazyCoreJetparkSearchbox, LazyCoreLayout, LazyCoreLinkWrapper, LazyCoreLocationPage, LazyCorePage, LazyCorePropelProductCard, LazyCoreSimpleCard, LazyCoreSimpleImage, LazyCoreSimpleLink, LazyCoreSimpleRichText, LazyCoreSimpleTitle, LazyCoreVideo, LazyCountdownCodeReveal, LazyCountdownEmailSubscription, LazyCountdownEmailSubscriptionForm, LazyCountdownTimer, LazyFooter, LazyHeader, LazyHeroCardLayout, LazyHeroCardLayoutHeader, LazyJPContactForm, LazyLinkList, LazyOTCookieButton, LazyOTCookieTables, LazyOtNotice, LazyPage, LazySearchbox, LazyTable, LazyTestimonial, LazyNuxtIcon } from '#components'
const lazyGlobalComponents = [
  ["Breadcrumbs", LazyBreadcrumbs],
["Button", LazyButton],
["CheckmarkList", LazyCheckmarkList],
["CoreAccordion", LazyCoreAccordion],
["CoreAccordionList", LazyCoreAccordionList],
["CoreAdvancedTitle", LazyCoreAdvancedTitle],
["CoreBanner", LazyCoreBanner],
["CoreCountdownSection", LazyCoreCountdownSection],
["CoreDynamicComponents", LazyCoreDynamicComponents],
["CoreEventComponent", LazyCoreEventComponent],
["CoreFeatureList", LazyCoreFeatureList],
["CoreJetparkSearchbox", LazyCoreJetparkSearchbox],
["CoreLayout", LazyCoreLayout],
["CoreLinkWrapper", LazyCoreLinkWrapper],
["CoreLocationPage", LazyCoreLocationPage],
["CorePage", LazyCorePage],
["CorePropelProductCard", LazyCorePropelProductCard],
["CoreSimpleCard", LazyCoreSimpleCard],
["CoreSimpleImage", LazyCoreSimpleImage],
["CoreSimpleLink", LazyCoreSimpleLink],
["CoreSimpleRichText", LazyCoreSimpleRichText],
["CoreSimpleTitle", LazyCoreSimpleTitle],
["CoreVideo", LazyCoreVideo],
["CountdownCodeReveal", LazyCountdownCodeReveal],
["CountdownEmailSubscription", LazyCountdownEmailSubscription],
["CountdownEmailSubscriptionForm", LazyCountdownEmailSubscriptionForm],
["CountdownTimer", LazyCountdownTimer],
["Footer", LazyFooter],
["Header", LazyHeader],
["HeroCardLayout", LazyHeroCardLayout],
["HeroCardLayoutHeader", LazyHeroCardLayoutHeader],
["JPContactForm", LazyJPContactForm],
["LinkList", LazyLinkList],
["OTCookieButton", LazyOTCookieButton],
["OTCookieTables", LazyOTCookieTables],
["OtNotice", LazyOtNotice],
["Page", LazyPage],
["Searchbox", LazySearchbox],
["Table", LazyTable],
["Testimonial", LazyTestimonial],
["NuxtIcon", LazyNuxtIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
