import { LDPlugin } from 'launchdarkly-vue-client-sdk';

export default defineNuxtPlugin((nuxtApp) => {
    const clientSideID = useRuntimeConfig().public.LAUNCH_DARKLY_CLIENT_KEY;
    try {
        nuxtApp.vueApp.use(LDPlugin, { clientSideID });
    } catch (err) {
        console.error('Error initializing LaunchDarkly plugin:', err);
    }
});
