import revive_payload_client_M7Qop4fSeeg_vw1XQ55hBgAL4X3DDYlKoEUCa9eBwiQ from "/codebuild/output/src3069017492/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@8.57.1_ioredis@_c26b79983e398c03b663a917f4a5ee24/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_848fXgkygZfEE4zXim6LE0FBhLd_ddWKcsrqqo7_80E from "/codebuild/output/src3069017492/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@8.57.1_ioredis@_c26b79983e398c03b663a917f4a5ee24/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_DugbrdACVqx9bcU7jlXhSS3foN4dYSD_XdYqGgzUHM4 from "/codebuild/output/src3069017492/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@8.57.1_ioredis@_c26b79983e398c03b663a917f4a5ee24/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig__XpxosqC_9DmvyA6VstMyN_TXEGnmpW1WT1VM2UdZkk from "/codebuild/output/src3069017492/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_vite@6.2.4_@types+node@22.13.17_jiti@2.4.2_terse_fa6e5bb9605e96033af7a8e14408468f/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_cUrULVqYWTrxtCqxIWeu61Yeb1kA77elSk4CjSVca_c from "/codebuild/output/src3069017492/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@8.57.1_ioredis@_c26b79983e398c03b663a917f4a5ee24/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_LpbPA4NQhK2ZE_0m7Hb2_4hQprG90URp_HyoLT_6y_A from "/codebuild/output/src3069017492/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@8.57.1_ioredis@_c26b79983e398c03b663a917f4a5ee24/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_7jd_xYar0QC184pyCO6H_xajsuu7ZR8j3TLRuxkeVIo from "/codebuild/output/src3069017492/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@8.57.1_ioredis@_c26b79983e398c03b663a917f4a5ee24/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_US2TYRFx1AEfoU4jynToug0dV5aj27SNEX3d74CHeKM from "/codebuild/output/src3069017492/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@8.57.1_ioredis@_c26b79983e398c03b663a917f4a5ee24/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_yyPFIJnMrljikG8d6c41JgG8rbOVUrEPbL80wKE56OI from "/codebuild/output/src3069017492/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.8.2_vue@3.5.13_typescript@5.8.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/codebuild/output/src3069017492/src/bitbucket.org/magusa/cavu-workspaces/apps/jetpark/.nuxt/components.plugin.mjs";
import prefetch_client_7beb8IMJyh__28dGN7yvxstp4m_i3MPf2jXmWNoOiUc from "/codebuild/output/src3069017492/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@8.57.1_ioredis@_c26b79983e398c03b663a917f4a5ee24/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_iWn8_GdXitKtqrehWImnVMjDFJxFmauHmo7ehWl7JRo from "/codebuild/output/src3069017492/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_HL_h5t_5U74OX3b6JbP_CFoumFsNeps8d6adWIxctqI from "/codebuild/output/src3069017492/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.16.2_@parcel+watcher@2.5.1_@type_6325ac20f7aff676f8e308274a5137f6/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_6uGsFWm9CZAu_S3rOEeJZrsLGQEC2alM8lBrGU_7nTA from "/codebuild/output/src3069017492/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxt+icon@1.11.0_magicast@0.3.5_vite@6.2.4_@types+node@22.13.17_jiti@2.4.2_terser@5.39_e7e19a8c1bfb06e764752a7386a60019/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_XuzOnwN_hojXs6kdzcQF5Yhb0nwhJqWKg11Hm91NiQg from "/codebuild/output/src3069017492/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@storyblok+nuxt@6.2.4_vue@3.5.13_typescript@5.8.2_/node_modules/@storyblok/nuxt/dist/runtime/plugin.js";
import plugin_RJSKME5Jr0msl7PpfWTXDSD62ktnDsJL_SL5EILduL8 from "/codebuild/output/src3069017492/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/pinia-plugin-persistedstate@4.2.0_@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.8.2_vue_6cc402edc64b5b431717c79808b7ec48/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import switch_locale_path_ssr_7Oli8fzrNIbdyrjI2z7xvf8BUiWdHPi2e015sM55NMY from "/codebuild/output/src3069017492/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@8.57.1_magicast@0.3.5_rollup@4.39.0_vue@3.5.13_typescript@5.8.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_TzTKasJjOcrPHX5tHbQmvG6ofI0fAkN8refvWmxAWbU from "/codebuild/output/src3069017492/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@8.57.1_magicast@0.3.5_rollup@4.39.0_vue@3.5.13_typescript@5.8.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_M1wY8pivT3tWJsGsRkGbxA1WyTDY0zLoI2IfYk6MoTE from "/codebuild/output/src3069017492/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@8.57.1_magicast@0.3.5_rollup@4.39.0_vue@3.5.13_typescript@5.8.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import base64_XVE2eeaoLb90zCtWHcXzwCVA_MZfMsafoJruckKetkE from "/codebuild/output/src3069017492/src/bitbucket.org/magusa/cavu-workspaces/apps/_base/plugins/base64.ts";
import launchDarkly_client_DyBMct62w08o_rlSdciqgPdaP_iEwzWuV6bFWD6DdSQ from "/codebuild/output/src3069017492/src/bitbucket.org/magusa/cavu-workspaces/apps/_base/plugins/launchDarkly.client.ts";
import ssg_detect_g8UH8lvCeSjg77pA5ySuUCkgCYINHaILJ11BHwUijC4 from "/codebuild/output/src3069017492/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@8.57.1_magicast@0.3.5_rollup@4.39.0_vue@3.5.13_typescript@5.8.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_M7Qop4fSeeg_vw1XQ55hBgAL4X3DDYlKoEUCa9eBwiQ,
  unhead_848fXgkygZfEE4zXim6LE0FBhLd_ddWKcsrqqo7_80E,
  router_DugbrdACVqx9bcU7jlXhSS3foN4dYSD_XdYqGgzUHM4,
  _0_siteConfig__XpxosqC_9DmvyA6VstMyN_TXEGnmpW1WT1VM2UdZkk,
  payload_client_cUrULVqYWTrxtCqxIWeu61Yeb1kA77elSk4CjSVca_c,
  navigation_repaint_client_LpbPA4NQhK2ZE_0m7Hb2_4hQprG90URp_HyoLT_6y_A,
  check_outdated_build_client_7jd_xYar0QC184pyCO6H_xajsuu7ZR8j3TLRuxkeVIo,
  chunk_reload_client_US2TYRFx1AEfoU4jynToug0dV5aj27SNEX3d74CHeKM,
  plugin_vue3_yyPFIJnMrljikG8d6c41JgG8rbOVUrEPbL80wKE56OI,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_7beb8IMJyh__28dGN7yvxstp4m_i3MPf2jXmWNoOiUc,
  plugin_iWn8_GdXitKtqrehWImnVMjDFJxFmauHmo7ehWl7JRo,
  plugin_HL_h5t_5U74OX3b6JbP_CFoumFsNeps8d6adWIxctqI,
  plugin_6uGsFWm9CZAu_S3rOEeJZrsLGQEC2alM8lBrGU_7nTA,
  plugin_XuzOnwN_hojXs6kdzcQF5Yhb0nwhJqWKg11Hm91NiQg,
  plugin_RJSKME5Jr0msl7PpfWTXDSD62ktnDsJL_SL5EILduL8,
  switch_locale_path_ssr_7Oli8fzrNIbdyrjI2z7xvf8BUiWdHPi2e015sM55NMY,
  route_locale_detect_TzTKasJjOcrPHX5tHbQmvG6ofI0fAkN8refvWmxAWbU,
  i18n_M1wY8pivT3tWJsGsRkGbxA1WyTDY0zLoI2IfYk6MoTE,
  base64_XVE2eeaoLb90zCtWHcXzwCVA_MZfMsafoJruckKetkE,
  launchDarkly_client_DyBMct62w08o_rlSdciqgPdaP_iEwzWuV6bFWD6DdSQ,
  ssg_detect_g8UH8lvCeSjg77pA5ySuUCkgCYINHaILJ11BHwUijC4
]