import validate from "/codebuild/output/src3069017492/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@8.57.1_ioredis@_c26b79983e398c03b663a917f4a5ee24/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45trailing_45slash_45global from "/codebuild/output/src3069017492/src/bitbucket.org/magusa/cavu-workspaces/apps/_base/middleware/redirectTrailingSlash.global.ts";
import redirect_45global from "/codebuild/output/src3069017492/src/bitbucket.org/magusa/cavu-workspaces/apps/jetpark/middleware/redirect.global.ts";
import manifest_45route_45rule from "/codebuild/output/src3069017492/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@8.57.1_ioredis@_c26b79983e398c03b663a917f4a5ee24/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45trailing_45slash_45global,
  redirect_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}